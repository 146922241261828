import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Divider,
  Popover,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/NotificationsOutlined";
import CustomScrollbar from "../../CommonComponents/CustomScrollbar";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
// const notifications = [
//   {
//     id: 1,
//     name: "Sara Johnson",
//     action: "mentioned you in a comment in a thread",
//     context: "Project List",
//     time: "2 mins ago",
//     avatar: "https://randomuser.me/api/portraits/women/1.jpg",
//   },
//   {
//     id: 2,
//     name: "Nick Jonas",
//     action: "created a new goal in the",
//     context: "design and development",
//     time: "30 mins ago",
//     avatar: "https://randomuser.me/api/portraits/men/2.jpg",
//   },
//   {
//     id: 3,
//     name: "Matt Hardy",
//     action: "requested to upgrade plan.",
//     time: "1 hr ago",
//     avatar: "https://randomuser.me/api/portraits/men/3.jpg",
//     buttons: true,
//   },
//   {
//     id: 4,
//     name: "Netty Miller",
//     action: "uploaded a design file.",
//     context: "landing_page_Version2.fig",
//     time: "3 hrs ago",
//     avatar: "https://randomuser.me/api/portraits/women/4.jpg",
//   },
// ];

const NotificationPanel = () => {
  const [notifications, setNofifications] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;

  const reducer = useSelector((state) => state);

  const {
    AccountReducer: { systemConstantAccount },
    ProjectReducer: { projectList },
  } = reducer;

  useEffect(() => {
    const notificationData = systemConstantAccount?.find(
      (o) => o.type === "NOTIFICATION"
    );

    if (notificationData?.details && notificationData?.details?.length > 0) {
      setNofifications([...notificationData?.details?.reverse()]);
    }
  }, [systemConstantAccount]);

  const getProjectName = (id) => {
    const projectName = projectList?.find((o) => o._id === id)?.projectName;
    return projectName;
  };

  const getUrl = (data) => {
    return `/main/${data?.projectId}/training-logs`;
  };

  return (
    <>
      <NotificationsIcon
        aria-describedby={id}
        color="inherit"
        onClick={handleClick}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            width: 450,
            bgcolor: "background.paper",
            borderRadius: 2,
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h6">Notifications</Typography>
            <Button size="small" color="primary">
              Mark all as read
            </Button>
          </Box>
          <CustomScrollbar height={"400px"}>
            <List>
              {notifications?.map((notification) => (
                <React.Fragment key={notification.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={notification.name}
                        src={notification.avatar}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {notification.msg === "Live Agent required." ? (
                            <a href={notification?.meetingUrl} target="_blank">
                              {" "}
                              {notification?.msg}{" "}
                            </a>
                          ) : (
                            <Link to={getUrl(notification)}>
                              {" "}
                              {notification.msg}{" "}
                            </Link>
                          )}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography
                            component="span"
                            variant="body2"
                            color="textSecondary"
                          >
                            Project Name :{" "}
                            {getProjectName(notification?.projectId)}
                            <Typography
                              component="span"
                              variant="body2"
                              color="primary"
                            >
                              {notification?.context}
                            </Typography>
                          </Typography>
                          <Typography
                            variant="caption"
                            display="block"
                            color="textSecondary"
                          >
                            {moment(notification?.timeStamp || "").format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </Typography>
                        </>
                      }
                    />
                    {notification.buttons && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                      >
                        <Button size="small" color="primary">
                          Accept
                        </Button>
                        <Button size="small" color="secondary">
                          Decline
                        </Button>
                      </Box>
                    )}
                  </ListItem>
                  {notification.id !== notifications.length && (
                    <Divider variant="inset" component="li" />
                  )}
                </React.Fragment>
              ))}
            </List>
          </CustomScrollbar>

          {/* <Box display="flex" justifyContent="center" mt={2}>
            <Button size="small" color="primary">
              View all notifications
            </Button>
          </Box> */}
        </Box>
      </Popover>
    </>
  );
};

export default NotificationPanel;
