import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Button,
  TextField,
  Typography,
  IconButton,
  Avatar,
  Grid,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { saveCompanyDeatilsAction } from "../../../Redux/Actions/Common.actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Styled component for CardContainer
const CardContainer = styled(Box)({
  backgroundColor: "#EAF1FB",
  padding: "20px",
  borderRadius: "10px",
  marginBottom: "20px",
});

// Initial data
const initialAgents = [
  {
    id: 1,
    photo: "https://via.placeholder.com/150",
    name: "John Doe",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    status: "Active",
  },
];

// LiveAgentCard component
const LiveAgentCard = ({ agent, onEdit }) => (
  <CardContainer>
    <Card>
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar src={agent.photo} />
          </Grid>
          <Grid item xs>
            <Typography variant="h6">{agent.name}</Typography>
            <Typography color="textSecondary">{agent.email}</Typography>
            <Typography color="textSecondary">{agent.phone}</Typography>
            <Typography color="textSecondary">{agent.status}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => onEdit(agent.id)}>
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </CardContainer>
);

// Main App component
const App = () => {
  const dispatch = useDispatch();
  const [agents, setAgents] = useState(initialAgents);
  const [editingAgent, setEditingAgent] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [newAgent, setNewAgent] = useState({
    photo: "https://via.placeholder.com/150",
    name: "",
    email: "",
    phone: "",
    status: "",
  });

  const reducer = useSelector((state) => state);

  const {
    AccountReducer: { systemConstant, systemConstantAccount },
  } = reducer;

  const navigate = useNavigate();
  useEffect(() => {
    const liveAgentData = systemConstantAccount?.find(
      (o) => o.type === "LIVE_AGENTS"
    );
    if (liveAgentData?.details && liveAgentData?.details?.length > 0) {
      setAgents([...liveAgentData?.details]);
    }
  }, [systemConstantAccount]);

  const addAgent = async () => {
    const toastId = toast.loading("Saving...");
    let obj = {
      type: "LIVE_AGENTS",
      details: {
        ...agents,
        ...newAgent,
      },
    };
    await dispatch(saveCompanyDeatilsAction(obj, toastId));
    setAgents([...agents, newAgent]);
    setIsCreating(false);
  };

  const editAgent = (id) => {
    const agent = agents.find((agent) => agent.id === id);
    setEditingAgent(agent);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingAgent({ ...editingAgent, [name]: value });
  };

  const saveAgent = () => {
    setAgents(
      agents.map((agent) =>
        agent.id === editingAgent.id ? editingAgent : agent
      )
    );
    setEditingAgent(null);
  };

  const handleCreateInputChange = (e) => {
    const { name, value } = e.target;
    setNewAgent({ ...newAgent, [name]: value });
  };

  return (
    <Box padding="20px">
      <button
        className="normal-button mr-1 float-right"
        onClick={() => navigate("/main/projects")}
      >
        {` Projects`}
      </button>

      <button onClick={() => setIsCreating(true)} className="normal-button">
        Add Live Agent
      </button>
      <Grid container spacing={3} marginTop="20px">
        {isCreating && (
          <Grid item xs={12} md={6} lg={4}>
            <Card>
              <CardContent>
                <Typography variant="h6">
                  Create New Agent{" "}
                  <i
                    className="float-right cursor-pointer"
                    onClick={() => setIsCreating(false)}
                  >
                    Close
                  </i>
                </Typography>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Name"
                  name="name"
                  value={newAgent.name}
                  onChange={handleCreateInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Email"
                  name="email"
                  value={newAgent.email}
                  onChange={handleCreateInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Phone"
                  name="phone"
                  value={newAgent.phone}
                  onChange={handleCreateInputChange}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="Status"
                  name="status"
                  value={newAgent.status}
                  onChange={handleCreateInputChange}
                />
                <button className="normal-button" onClick={() => addAgent()}>
                  Save
                </button>
              </CardContent>
            </Card>
          </Grid>
        )}
        {agents?.map((agent) => (
          <Grid item xs={12} md={6} lg={4} key={agent.id}>
            <LiveAgentCard agent={agent} onEdit={editAgent} />
          </Grid>
        ))}
      </Grid>
      {/* {editingAgent && (
        <Box marginTop="20px">
          <Card>
            <CardContent>
              <Typography variant="h6">Edit Agent</Typography>
              <TextField
                fullWidth
                margin="normal"
                label="Name"
                name="name"
                value={editingAgent.name}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                name="email"
                value={editingAgent.email}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Phone"
                name="phone"
                value={editingAgent.phone}
                onChange={handleInputChange}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Status"
                name="status"
                value={editingAgent.status}
                onChange={handleInputChange}
              />
              <Button variant="contained" color="primary" onClick={saveAgent}>
                Save
              </Button>
            </CardContent>
          </Card>
        </Box>
      )} */}
    </Box>
  );
};

export default App;
