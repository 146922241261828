import React, { useEffect, useState } from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement,
} from "chart.js";
import { Box, Card, Container, Grid, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getDealsHourlyAction,
  getDealsWeeklyAction,
  getFlowCacheDataAction,
} from "../../../Redux/Actions/Common.actions";
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LineElement,
  PointElement
);

const Dashboard = () => {
  const pieData = {
    labels: ["Automobiles", "Healthcare", "Technology", "Education", "Retail"],
    datasets: [
      {
        label: "Number of Deals",
        data: [120, 190, 30, 50, 80],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
        hoverBackgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
    // layout: {
    //   padding: {
    //     left: 20, // Adjust the left padding as needed
    //     right: 20,
    //     top: 20,
    //     bottom: 20,
    //   },
    // },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          color: "black", // Y-axis color
        },
      },
      y: {
        grid: {
          drawBorder: true,
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
        },
        ticks: {
          color: "black", // Y-axis color
          callback: function (value) {
            return Number.isInteger(value) ? value : ""; // Display only integers
          },
        },
      },
    },
  };

  const PieChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
      mode: "index",
    },
    scales: {
      y: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        grid: {
          drawBorder: false,
          display: false,
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  const barLineSize = { width: 400, height: 300 };
  const reducer = useSelector((state) => state);
  const {
    ProjectReducer: { projectList },
    AccountReducer: { selectedAccount },
  } = reducer;
  console.log("selectedAccount", selectedAccount);
  const [chartsState, setChartsState] = useState({
    barChartLabels: [],
    barChartValues: [],
    lineChartValues: [],
    lineChartLabels: [],
  });
  const [countsValues, setCountsValues] = useState({
    dealsNumber: "0",
    dealsText: "",
    totalFollowUpsPendingCount: "0",
    totalMeetingPendingCount: "0",
  });
  const { barChartLabels, barChartValues, lineChartLabels, lineChartValues } =
    chartsState;
  const dispatch = useDispatch();

  const calculatePercentage = (data) => {
    const today = data.find((item) => item._id === "2024-08-12");
    const yesterday = data.find((item) => item._id === "2024-08-11");

    const todayCount = today ? today.count : 0;
    const yesterdayCount = yesterday ? yesterday.count : 0;

    // Calculate percentage change
    const percentageChange =
      yesterdayCount === 0
        ? todayCount > 0
          ? Infinity
          : 0
        : ((todayCount - yesterdayCount) / yesterdayCount) * 100;

    console.log("Today's count:", todayCount);
    console.log(
      "Percentage change from yesterday:",
      percentageChange.toFixed(2) + "%"
    );

    const changeDescription =
      percentageChange > 0
        ? `${percentageChange.toFixed(2)}% more than yesterday`
        : `${Math.abs(percentageChange.toFixed(2))}% less than yesterday`;

    return { changeDescription, todayCount };
  };
  useEffect(() => {
    const init = async () => {
      const startDate = moment().subtract(6, "days").format("YYYY-MM-DD");
      const startDate1 = moment().subtract(1, "days").format("YYYY-MM-DD");

      let hourResponse = await dispatch(
        getDealsHourlyAction(`from=${"2024-08-02"}`)
      );
      let dealsReponse = await dispatch(
        getDealsWeeklyAction(`from=${startDate}`)
      );
      let dealsReponse1 = await dispatch(
        getDealsWeeklyAction(`from=${startDate1}`)
      );

      let cachedData = await dispatch(getFlowCacheDataAction());
      let dealcountRes = calculatePercentage(dealsReponse1?.data);
      let barChartlabel = dealsReponse?.data?.map((item) => item._id);
      let barChartValue = dealsReponse?.data?.map((item) => item.count);
      let lineChartValue = hourResponse?.data[0]?.hourlyCount?.map(
        (item) => item.count
      );
      let lineChartLabel = hourResponse?.data[0]?.hourlyCount?.map(
        (item) =>
          item?.hour && moment().startOf("day").hour(item.hour).format("h:mm A")
      );

      let followUpCount = cachedData?.data?.filter(
        (item) => item.flowCacheData.triggerType === "FOLLOW_UP"
      ).length;
      let meetingPendingCount = cachedData?.data?.filter(
        (item) => item.flowCacheData.triggerType === "MEETING"
      ).length;

      setCountsValues((prev) => ({
        ...prev,
        dealsNumber: dealcountRes?.todayCount || 0,
        dealsText: dealcountRes?.changeDescription || "",
        totalFollowUpsPendingCount: followUpCount || 0,
        totalMeetingPendingCount: meetingPendingCount || 0,
      }));
      setChartsState((prev) => ({
        ...prev,
        barChartLabels: barChartlabel || [],
        barChartValues: barChartValue || [],
        lineChartValues: lineChartValue || [],
        lineChartLabels: lineChartLabel || [],
      }));
    };

    init();
  }, []);

  const barData = {
    labels: [...barChartLabels],
    datasets: [
      {
        label: "Deals Incoming",
        data: [...barChartValues],
        backgroundColor: "#36A2EB",
      },
    ],
  };

  const lineData = {
    labels: ["00:00", ...lineChartLabels],
    datasets: [
      {
        label: "Leads Coming In",
        data: ["0", ...lineChartValues],
        fill: false,
        borderColor: "#009199",
        backgroundColor: "#009199",
        tension: 0.4,
      },
    ],
  };
  const cardData = [
    {
      title: "Credits",
      files: "Expire on date",
      // size: "50 used in meeting",
      color: "#4285F4",
      number: selectedAccount?.credits?.toFixed(2) || 0,
    },
    {
      title: "New Deals",
      files: countsValues?.dealsText,
      // size: "650 MB",
      color: "#4285F4",
      number: countsValues?.dealsNumber,
    },
    {
      title: "Total Follow Ups",
      files: "Pending",
      // size: "750 MB",
      color: "#34A853",
      number: countsValues?.totalFollowUpsPendingCount,
    },
    {
      title: "Total Meetings",
      files: "Pending",
      // size: "20 MB",
      color: "#34A853",
      number: countsValues?.totalMeetingPendingCount,
    },
  ];

  return (
    // <Container
    //   maxWidth="lg"
    //   sx={{ backgroundColor: "var(--background-color)", padding: 0 }}
    // >
    //   <Typography className="font-size-20" gutterBottom>
    //     Dashboard
    //   </Typography>

    //   <Container
    //     maxWidth="lg"
    //     sx={{
    //       paddingBottom: "20px",
    //       color: "#fff",
    //       display: "flex",
    //       alignItems: "center",
    //       justifyContent: "space-between",
    //       margin: 0,
    //     }}
    //   >
    //     <Grid container spacing={3} sx={{ flexGrow: 1 }}>
    //       {cardData.map((card, index) => (
    //         <Grid item xs={12} sm={6} key={index}>
    //           <Paper
    //             sx={{
    //               padding: "20px",
    //               backgroundColor: card.color,
    //               borderRadius: "8px",
    //               color: "#fff",
    //               display: "flex",
    //               justifyContent: "space-between",
    //               alignItems: "center",
    //             }}
    //           >
    //             <div>
    //               <Typography variant="h6" gutterBottom>
    //                 {card.title}
    //               </Typography>
    //               <Typography variant="body2">{card.files}</Typography>
    //               <Typography variant="body2">{card.size}</Typography>
    //             </div>
    //             <div
    //               style={{
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center",
    //                 width: "90px",
    //                 height: "50px",
    //                 borderRadius: "8px",
    //                 backgroundColor: "#333",
    //               }}
    //             >
    //               <Typography className="font-size-18">
    //                 {card.number}
    //               </Typography>
    //             </div>
    //           </Paper>
    //         </Grid>
    //       ))}
    //     </Grid>
    //     <Box sx={{ marginLeft: "20px", flexGrow: 1 }}>
    //       <Pie data={pieData} options={PieChartOptions} />

    //       {/* <Line data={lineData} options={chartOptions} /> */}
    //     </Box>
    //   </Container>
    //   <Grid container spacing={3}>
    //     <Grid item xs={12} md={6}>
    //       <Typography
    //         className="font-size-16"
    //         gutterBottom
    //         sx={{ color: "#fff" }}
    //       >
    //         Time when leads coming
    //       </Typography>
    //       <Paper
    //         sx={{
    //           padding: "16px",
    //           backgroundColor: "#1a1a1a",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           maxHeight: "400px",
    //           minHeight: "400px",
    //         }}
    //       >
    //         <Line data={lineData} options={chartOptions} />
    //       </Paper>
    //     </Grid>
    //     <Grid item xs={12} md={6}>
    //       <Typography
    //         className="font-size-16"
    //         gutterBottom
    //         sx={{ color: "#fff" }}
    //       >
    //         {" "}
    //         Day to Day Leads Coming
    //       </Typography>
    //       <Paper
    //         sx={{
    //           padding: "16px",
    //           backgroundColor: "#1a1a1a",
    //           display: "flex",
    //           justifyContent: "center",
    //           alignItems: "center",
    //           maxHeight: "400px",
    //           minHeight: "400px",
    //         }}
    //       >
    //         <Bar data={barData} options={chartOptions} {...barLineSize} />
    //       </Paper>
    //     </Grid>
    //   </Grid>
    // </Container>

    <Grid container spacing={3} padding={3}>
      <Grid item xs={12} md={4}>
        <Card
          style={{
            width: 403.52,
            height: 572,
            background: "linear-gradient(200deg, #CDF4F6 7%, white 41%)",
            borderRadius: 20.8,
            border: "0.5px #E5E7EB solid",
            padding: "26px",
          }}
        >
          <Grid container spacing={2}>
            {/* Stats Cards */}
            <Grid item xs={6}>
              <Typography className="font-size-30">Hey</Typography>
              <Typography className="font-size-30">Rohan 👋</Typography>
              <Typography variant="body2" color="textSecondary">
                Chop your own wood and it will warm you twice 🌟
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Card
                style={{
                  padding: "20px",
                  height: "50%",
                  background: "transparent",
                  border: "none",
                  boxShadow: "none",
                  float: "right",
                }}
              >
                <Pie data={pieData} options={PieChartOptions} />
              </Card>
            </Grid>
          </Grid>

          <Grid container spacing={2} marginTop={2}>
            {/* Stats Cards */}
            <Grid item xs={6}>
              <Card
                style={{
                  width: 160.16,
                  height: 143.52,
                  borderRadius: 13.11,
                  padding: "12px",
                  textAlign: "center",
                  color: "#000712",
                  fontSize: 26,
                  fontFamily: "Instrument Sans",
                  fontWeight: "700",
                  wordWrap: "break-word",
                  background: "#EAF5FF",
                }}
              >
                <Typography className="mt-4 font-size-23">90.10</Typography>
                <Typography
                  className="mt-2 font-size-18"
                  style={{ color: "#6B7280" }}
                >
                  Credits
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                style={{
                  width: 160.16,
                  height: 143.52,
                  background: "#EAF5FF",
                  borderRadius: 13.11,
                  padding: "12px",
                  textAlign: "center",
                  color: "#000712",
                  fontSize: 26,
                  fontFamily: "Instrument Sans",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                <Typography className="mt-4 font-size-23">0</Typography>
                <Typography
                  className="mt-2 font-size-18"
                  style={{ color: "#6B7280" }}
                >
                  New Deals
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                style={{
                  width: 160.16,
                  height: 143.52,
                  background: "#FAEAFF",
                  borderRadius: 13.11,
                  padding: "12px",
                  textAlign: "center",
                  color: "#000712",
                  fontSize: 26,
                  fontFamily: "Instrument Sans",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                <Typography className="mt-4 font-size-23">0</Typography>
                <Typography
                  className="mt-2 font-size-18"
                  style={{ color: "#6B7280" }}
                >
                  Follow-Ups Pending
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card
                style={{
                  width: 160.16,
                  height: 143.52,
                  background: "#FFEAEA",
                  borderRadius: 13.11,
                  padding: "12px",
                  textAlign: "center",
                  color: "#000712",
                  fontSize: 26,
                  fontFamily: "Instrument Sans",
                  fontWeight: "700",
                  wordWrap: "break-word",
                }}
              >
                <Typography className="mt-4 font-size-23">0</Typography>
                <Typography
                  className="mt-2 font-size-18"
                  style={{ color: "#6B7280" }}
                >
                  Meetings Pending
                </Typography>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Grid>

      {/* Line Chart */}
      <Grid item xs={12} md={8}>
        <Card
          style={{
            padding: "26px",
            height: "100%",
            borderRadius: 31.96,
            border: "1px #E5E7EB solid",
            marginLeft: "20px",
          }}
        >
          <Typography className="font-size-22">
            Lead Connection Strength: 🟢 Strong
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Time when leads are coming
          </Typography>
          <Line data={lineData} options={chartOptions} className="mt-8" />{" "}
        </Card>
      </Grid>

      {/* Pie Chart */}
      <Grid item xs={12} md={4}>
        <Card
          style={{
            padding: "20px",
            height: "100%",
            borderRadius: 20.8,
            border: "0.5px #E5E7EB solid",
            padding: "26px",
            width: 403.52,
          }}
        >
          <Pie data={pieData} options={PieChartOptions} />
        </Card>
      </Grid>

      {/* Bar Chart */}
      <Grid item xs={12} md={8}>
        <Card
          style={{
            padding: "26px",
            height: "100%",
            borderRadius: 31.96,
            border: "1px #E5E7EB solid",
            marginLeft: "20px",
          }}
        >
          <Typography className="font-size-22">
            Day to Day Leads Coming
          </Typography>
          <Bar data={barData} options={chartOptions} className="mt-8" />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
