import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCp8r5w24WfiunNjrGmR8qdw7j5vHLaCfM",
    authDomain: "lilchirp-io.firebaseapp.com",
    databaseURL: "https://lilchirp-io-default-rtdb.firebaseio.com",
    projectId: "lilchirp-io",
    storageBucket: "lilchirp-io.appspot.com",
    messagingSenderId: "854246583930",
    appId: "1:854246583930:web:dbddf122744d48a24895c3"
  };
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);