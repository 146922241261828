import React, { useState } from "react";
import { BootStrapInput } from "../CommonComponents/Input";
import { useDispatch } from "react-redux";
import {
  loginAction,
  setSelectedAccountAction,
} from "../../Redux/Actions/Common.actions";
import { Box, Container, Typography, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import TopRightLogo from "../CommonComponents/TopRightLogo";
import { axiosClient } from "../../Constants/Axios";
import { toast } from "react-toastify";
import Eva from "../../Assests/Images/Eva.svg";
import Joey from "../../Assests/Images/Joey.svg";
import Hen from "../../Assests/Images/hen.svg";

const theme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
  },
});

function Login() {
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const { email, password } = state;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const loginFunction = async () => {
    const toastId = toast.loading("Logging In...");

    let obj = {
      email,
      password,
    };
    let res = await dispatch(loginAction(obj));

    axiosClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${res.token}`;
    axiosClient.defaults.headers.common["dbUrl"] = `${
      res?.accounts[0]?.accountUrl || "Vodex_1718870853046"
    }`;
    dispatch(setSelectedAccountAction(res?.accounts[0]));
    navigate("/main/dashboard");
    toast.update(toastId, {
      render: "Welcome",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <TopRightLogo />
      <Grid container style={{ height: "100vh" }} className="bg">
        {/* Left side: 60% */}
        <Grid item xs={12} md={7} sx={{ padding: "0 2rem" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography align="left" className="font-size-50 mt-20 mb-7">
              Meet Your Sales Team
            </Typography>
            <Grid container spacing={3} className="ml-5">
              <Grid item>
                <img src={Eva} alt="Eva" height={"250px"} />
                <Typography align="center">Eva, AI sales agent</Typography>
              </Grid>
              <Grid item className="mt-14">
                <img src={Joey} alt="Joey" height={"250px"} />
                <Typography align="center">Joey, AI sales agent</Typography>
              </Grid>
              <Grid item>
                <img src={Hen} alt="Hen" height={"250px"} />
                <Typography align="center">Hen, AI sales agent</Typography>
              </Grid>
            </Grid>
            <Typography align="left" gutterBottom className="font-size-38 mt-4">
              Your next Gen AI agents
            </Typography>
          </Box>
        </Grid>

        {/* Right side: 40% */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Container maxWidth="xs">
              <Typography
                align="left"
                className="font-size-22 color-black"
                gutterBottom
                sx={{ fontSize: "24px", color: "#000" }}
              >
                Welcome back!
              </Typography>
              <Typography
                align="left"
                gutterBottom
                className="font-size-16 color-black"
                sx={{ fontSize: "16px", color: "#000" }}
              >
                Please enter your credentials to sign in!
              </Typography>
              <br />
              <form noValidate autoComplete="off">
                <BootStrapInput
                  value={email}
                  name={"email"}
                  label={"Email"}
                  onChange={onChange}
                  className={"ml-1 mb-3 w-90"}
                />
                <BootStrapInput
                  value={password}
                  name={"password"}
                  label={"Password"}
                  onChange={onChange}
                  type={"password"}
                  className={"ml-1 mb-3 w-90"}
                />

                <button
                  className="gradient-button ml-1 mt-4 w-90"
                  onClick={loginFunction}
                  type="button"
                >
                  Sign In
                </button>
              </form>
              <br />
            </Container>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;
