import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";

const App = () => {
  const reducer = useSelector((state) => state);
  const {
    DealsReducer: { dealsList },
    ProjectReducer: { selectedProject },
  } = reducer;
  const [screen, setScreen] = useState(1);
  const [screenName, setScreenName] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const [inputData, setInputData] = useState({});
  const [open, setOpen] = useState(false);
  const [excludedItems, setExcludedItems] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleScreenNameSubmit = () => {
    setOpen(false);
    setScreen(2);
  };

  const handleItemToggle = (item) => {
    const currentIndex = selectedItems.findIndex(
      (selected) => selected.data.path === item?.data?.path
    );
    const newSelectedItems = [...selectedItems];

    if (currentIndex === -1) {
      newSelectedItems.push(item);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    setSelectedItems(newSelectedItems);
  };

  const handleSave = () => {
    const data = selectedItems.map((item) => ({
      ...item,
      description: inputData[item.data.path]?.description || "",
      sleep: inputData[item.data.path]?.sleep || "",
    }));

    setSavedData([...savedData, { screenName, items: data }]);
    setExcludedItems([
      ...excludedItems,
      ...selectedItems.map((item) => item.data.path),
    ]);
    setSelectedItems([]);
    setInputData({});
    setScreenName("");
    setScreen(1); // Go back to the first screen after saving
  };

  const handleInputChange = (item, inputName, value) => {
    setInputData((prevState) => ({
      ...prevState,
      [item.data.path]: {
        ...prevState[item.data.path],
        [inputName]: value,
      },
    }));
  };

  const filteredItems = selectedProject?.xpath?.filter(
    (item) => !excludedItems.includes(item.data.path)
  );

  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        height: "100vh",
        padding: "20px",
      }}
    >
      {screen === 1 && (
        <div>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Add New Screen
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Enter Screen Name</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Screen Name"
                fullWidth
                value={screenName}
                onChange={(e) => setScreenName(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleScreenNameSubmit} color="primary">
                Next
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {screen === 2 && (
        <div>
          <List>
            {filteredItems?.map((item) => (
              <ListItem
                key={item.data.path}
                onClick={() => handleItemToggle(item)}
              >
                <Checkbox
                  edge="start"
                  checked={
                    selectedItems.findIndex(
                      (selected) => selected.data.path === item.data.path
                    ) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={item.data.desc} />
              </ListItem>
            ))}
          </List>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setScreen(3)}
            disabled={selectedItems.length === 0}
          >
            Save
          </Button>
        </div>
      )}
      {screen === 3 && (
        <div>
          {selectedItems.map((item) => (
            <div key={item.data.path} style={{ marginBottom: "20px" }}>
              <Typography variant="h6" style={{ color: "white" }}>
                {item.data.desc}
              </Typography>
              <Box display="flex" flexDirection="column" mt={2}>
                <TextField
                  label="Description"
                  value={inputData[item.data.path]?.description || ""}
                  onChange={(e) =>
                    handleInputChange(item, "description", e.target.value)
                  }
                  margin="normal"
                  style={{ backgroundColor: "white", borderRadius: "4px" }}
                />
                <TextField
                  label="Sleep"
                  value={inputData[item.data.path]?.sleep || ""}
                  onChange={(e) =>
                    handleInputChange(item, "sleep", e.target.value)
                  }
                  margin="normal"
                  style={{ backgroundColor: "white", borderRadius: "4px" }}
                />
              </Box>
            </div>
          ))}
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <Typography variant="h6" style={{ color: "white" }}>
          Saved Screens
        </Typography>
        {savedData?.map((screen, index) => (
          <Paper
            key={index}
            style={{
              marginBottom: "20px",
              padding: "10px",
              backgroundColor: "#333",
              color: "white",
            }}
          >
            <Typography variant="h6">{screen.screenName}</Typography>
            {screen.items.map((item) => (
              <div key={item.id}>
                <Typography variant="body1">{item.text}</Typography>
                <Typography variant="body2">
                  Text: {item.description}
                </Typography>
                <Typography variant="body2">Sleep: {item.sleep}</Typography>
              </div>
            ))}
          </Paper>
        ))}
      </div>
    </div>
  );
};

export default App;
