import * as React from "react";
import TextField from "@mui/material/TextField";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import {
  alpha,
  createTheme,
  styled,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import ResizeObserver from "resize-observer-polyfill";
import { FormControl, InputBase, InputLabel } from "@mui/material";
import { RequiredStar } from "./Common";

const customTheme = (outerTheme) =>
  createTheme({
    palette: {
      mode: outerTheme.palette.mode,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "--TextField-brandBorderColor": "#E0E3E7",
            "--TextField-brandBorderHoverColor": "#B2BAC2",
            "--TextField-brandBorderFocusedColor": "#6F7E8C",
            "& label.Mui-focused": {
              color: "var(--TextField-brandBorderFocusedColor)",
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            borderColor: "var(--border-color)",
          },
          root: {
            [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderHoverColor)",
            },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: "var(--TextField-brandBorderFocusedColor)",
            },
            color: "#fff", // Set the color of the input text to purple
          },
          input: {
            color: "#fff", // Ensure the input text color is purple
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            "&::before, &::after": {
              borderBottom: "2px solid var(--primary-color)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--primary-color)",
            },
            "&.Mui-focused:after": {
              borderBottom: "2px solid var(--primary-color)",
            },
            color: "#fff", // Set the color of the input text to purple
          },
          input: {
            color: "#fff", // Ensure the input text color is purple
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            "&::before": {
              borderBottom: "2px solid var(--TextField-brandBorderColor)",
            },
            "&:hover:not(.Mui-disabled, .Mui-error):before": {
              borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            },
            "&.Mui-focused:after": {
              borderBottom:
                "2px solid var(--TextField-brandBorderFocusedColor)",
            },
            color: "#fff", // Set the color of the input text to purple
          },
          input: {
            color: "#fff", // Ensure the input text color is purple
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "#fff",
            "&.Mui-focused": {
              color: "#fff", // Ensure the focused label remains red
            },
          },
        },
      },
    },
  });

export function Input({
  label,
  onChange,
  name,
  value,
  className,
  type,
  placeholder,
}) {
  const outerTheme = useTheme();
  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <TextField
        label={label}
        onChange={onChange}
        name={name}
        className={className || "m-3"}
        value={value}
        type={type || "text"}
        placeholder={placeholder}
      />
    </ThemeProvider>
  );
}

export function MultiLineInput({
  label,
  onChange,
  name,
  value,
  width,
  className,
}) {
  const outerTheme = useTheme();
  const textFieldRef = React.useRef(null);
  React.useEffect(() => {
    if (textFieldRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        try {
          // Handle resize logic if needed
        } catch (error) {
          // Ignore the error
          console.error("ResizeObserver error:", error);
        }
      });

      resizeObserver.observe(textFieldRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);
  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      <TextField
        ref={textFieldRef}
        label={label}
        onChange={onChange}
        name={name}
        className={className || "m-3"}
        value={value}
        id="outlined-multiline-static"
        multiline
        rows={10}
        style={{ width: width }}
        fullWidth
      />
    </ThemeProvider>
  );
}

export function MultiLineGrowInput({
  label,
  onChange,
  name,
  value,
  width,
  className,
  aiSuggested = false,
}) {
  const outerTheme = useTheme();
  const textFieldRef = React.useRef(null);
  React.useEffect(() => {
    if (textFieldRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        try {
          // Handle resize logic if needed
        } catch (error) {
          // Ignore the error
          console.error("ResizeObserver error:", error);
        }
      });

      resizeObserver.observe(textFieldRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);
  return (
    <ThemeProvider theme={customTheme(outerTheme)}>
      {aiSuggested && (
        <span
          style={{
            position: "absolute",
            background: "white",
            color: "black",
            height: "35px",
            width: "100px",
            marginLeft: "-75px",
            marginTop: "12px",
            borderRadius: "10px",
            fontWeight: 600,
            zIndex: 1000,
          }}
        >
          <p className="mt-2"> AI Suggested</p>
        </span>
      )}
      <TextField
        ref={textFieldRef}
        label={label}
        onChange={onChange}
        name={name}
        className={className || "m-3"}
        value={value}
        id="outlined-multiline-static"
        multiline
        maxRows={8}
        style={{ width: width }}
        fullWidth
      />
    </ThemeProvider>
  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "1px solid",
    fontSize: 16,
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

export function BootStrapInput({
  label,
  onChange,
  name,
  value,
  className,
  type,
  placeholder,
  style = {},
  required = true,
}) {
  return (
    <FormControl variant="standard" className={className || "m-3"}>
      <InputLabel sx={{ color: "black" }} shrink htmlFor="bootstrap-input">
        {label} {required && <RequiredStar />}
      </InputLabel>
      <BootstrapInput
        onChange={onChange}
        name={name}
        value={value}
        type={type || "text"}
        placeholder={placeholder}
        sx={{ ...style }}
      />
    </FormControl>
  );
}

export function BootStrapInputClick({
  label,
  name,
  value,
  className,
  type,
  placeholder,
  style = {},
  onClick,
  required = false,
}) {
  console.log(onClick);
  return (
    <FormControl variant="standard" className={className || "m-3"}>
      <InputLabel sx={{ color: "black" }} shrink htmlFor="bootstrap-input">
        {label} <RequiredStar />
      </InputLabel>
      <BootstrapInput
        name={name}
        value={value}
        type={type || "text"}
        placeholder={placeholder}
        sx={{ ...style }}
        onClick={onClick}
      />
    </FormControl>
  );
}
