import "./App.css";
import "./Assests/Css/Common.css";
import Login from "./Components/Pages/Login";
import OnboardingRoutes from "./Components/Onboarding/OnboardingRoutes";
import OnboardingContext from "./Components/Onboarding/OnboardingContext";
import { Scrollbars } from "react-custom-scrollbars";
import { renderThumb } from "./Components/CommonComponents/Common";
import { Suspense, lazy, useEffect } from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import { routes } from "./Components/Configs/routes";
import { SuspenseLoader } from "./Components/CommonComponents/CommonUiComponent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DemoForm from "./Components/Pages/DemoForm";
import { getToken } from "firebase/messaging";
import { messaging } from "./Components/Firebase/Firebase";
import {
  getAccountSystemConstantAction,
  getGlobalSystemConstantAction,
} from "./Redux/Actions/Common.actions";
import { useDispatch, useSelector } from "react-redux";
const ErrorComponent = lazy(() =>
  import("./Components/CommonComponents/CommonUiComponent/ErrorComponent")
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route
        path="/get-started/*"
        element={
          <OnboardingContext>
            <OnboardingRoutes />
          </OnboardingContext>
        }
      />

      <Route path={routes.main.path} element={<routes.main.element />}>
        <Route
          path={`${routes.main.path}/:projectId${routes.screenConfig.path}`}
          element={<routes.screenConfig.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}/:projectId${routes.trainginLogs.path}`}
          element={<routes.trainginLogs.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}/${routes.liveAgent.path}`}
          element={<routes.liveAgent.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}/${routes.companyDetails.path}`}
          element={<routes.companyDetails.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}/:projectId${routes.calender.path}`}
          element={<routes.calender.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}/:projectId${routes.dealsView.path}`}
          element={<routes.dealsView.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}/:projectId${routes.deals.path}/:type`}
          element={<routes.deals.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}/:projectId${routes.callSettings.path}`}
          element={<routes.callSettings.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}${routes.projects.path}`}
          element={<routes.projects.element />}
          errorElement={<ErrorComponent />}
        />
        <Route
          path={`${routes.main.path}${routes.dashboard.path}`}
          element={<routes.dashboard.element />}
          errorElement={<ErrorComponent />}
        />
      </Route>

      <Route path={routes.invalid.path} element={<ErrorComponent />} />
      <Route path="/login" element={<Login />} />
      <Route path="/demo-form" element={<DemoForm />} />
      <Route path="/" element={<Login />} />
    </Route>
  )
);

function App() {
  console.log("ॐ नमः शिवाय");
  const dispatch = useDispatch();
  const reducer = useSelector((state) => state);
  const {
    AccountReducer: { selectedAccount },
  } = reducer;

  useEffect(() => {
    const init = async () => {
      dispatch(getGlobalSystemConstantAction());
      dispatch(getAccountSystemConstantAction());
    };

    init();
  }, [selectedAccount]);

  return (
    <Scrollbars
      style={{
        height: "100vh",
        color: "white",
      }}
      renderThumbVertical={renderThumb}
      autoHide={true}
    >
      <ToastContainer />

      <Suspense fallback={<SuspenseLoader />}>
        <RouterProvider router={router} />
      </Suspense>
    </Scrollbars>
  );
}

export default App;
