import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import { saveCompanyDeatilsAction } from "../../../Redux/Actions/Common.actions";
import { Input, MultiLineGrowInput } from "../../CommonComponents/Input";
import CenterContainer from "../../CommonComponents/CenterContainer";
import timezones from "../../CommonComponents/timezones.json";
import { Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  convertTime,
  reverseConvertTime,
  timeArray,
} from "../../CommonComponents/Common";
import { getCompanySummaryAction } from "../../../Redux/Actions/AI.actions";

const arrayA = [1, 1, 1, 1, 1, 1, 1];
const arrayB = [1, 1, 1, 1, 1, 0, 0];

const arraysEqual = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;
  return arr1.every((value, index) => value === arr2[index]);
};

const checkWhichArrayMatches = (backendArray) => {
  if (arraysEqual(backendArray, arrayA)) {
    return "7";
  } else if (arraysEqual(backendArray, arrayB)) {
    return "5";
  }
};

const hoursToMinutes = (hours) => {
  return hours * 60;
};

const daysToArray = (days) => {
  if (days === 7) {
    return Array(7).fill(1);
  } else if (days === 5) {
    return [1, 1, 1, 1, 1, 0, 0];
  } else {
    return [];
  }
};
function CompanyDetails() {
  const [state, setState] = useState({
    from: "",
    to: "",
    days: "",
    timezone: "",
    homepageUrl: "",
    summary: "",
  });

  const reducer = useSelector((state) => state);

  const {
    AccountReducer: { systemConstant, systemConstantAccount },
  } = reducer;

  const navigate = useNavigate();
  useEffect(() => {
    const companyDetailsData = systemConstantAccount?.find(
      (o) => o.type === "COMPANY_DETAILS"
    );

    if (
      companyDetailsData?.companyDetails &&
      companyDetailsData?.companyDetails?.length > 0
    ) {
      let obj = companyDetailsData?.companyDetails[0];
      const fromHours = Number(obj?.original_timing?.startTime) / 60;
      const toHours = Number(obj?.original_timing?.endTime) / 60;

      setState((prev) => ({
        ...prev,
        from: fromHours < 10 ? `0${fromHours}` : `${fromHours}`,
        to: toHours < 10 ? `0${toHours}` : `${toHours}`,
        days: checkWhichArrayMatches(obj?.days?.value),
        timezone: reverseConvertTime(obj?.timezone),
        homepageUrl: obj?.homepageUrl,
        summary: obj?.summary,
      }));
    }
  }, [systemConstantAccount]);
  const { from, to, days, timezone, summary, homepageUrl } = state;
  console.log(from);
  console.log(to);
  const dispatch = useDispatch();
  const onChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const processData = (inputData) => {
    const { from, to, days } = inputData;

    const fromMinutes = hoursToMinutes(parseInt(from));
    const toMinutes = hoursToMinutes(parseInt(to));

    const daysArray = daysToArray(parseInt(days));

    return {
      fromMinutes,
      toMinutes,
      daysArray,
    };
  };

  const saveCompanyDeatils = async () => {
    const toastId = toast.loading("Saving...");

    const inputData = {
      from: from,
      to: to,
      days: days,
    };

    const result = processData(inputData);
    let obj = {
      type: "COMPANY_DETAILS",
      companyDetails: {
        timing: {
          startTime: result.fromMinutes,
          endTime: result.toMinutes,
        },
        days: { value: result.daysArray },
        timezone: convertTime(timezone),
        homepageUrl,
        summary,
      },
    };
    await dispatch(saveCompanyDeatilsAction(obj, toastId));
  };

  const weekOptions = [
    { label: "Whole Week", value: "7" },
    { label: "5 days", value: "5" },
  ];

  const getCompanySummary = async () => {
    let res = await dispatch(
      getCompanySummaryAction({ websites: [homepageUrl], extra_data: "" })
    );
    console.log("rss", res);
    setState((prev) => ({
      ...prev,
      summary: res.summary,
    }));
  };
  return (
    <div>
      <button
        className="normal-button mr-1 float-right"
        onClick={() => navigate("/main/projects")}
      >
        {` Projects`}
      </button>
      <br />
      <br />

      <label>Agent Time Availability</label>
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <Select
            maxMenuHeight={180}
            className="basic-single mb-2 "
            classNamePrefix="select"
            value={timeArray?.find((e) => e.value === from)}
            isSearchable={true}
            options={timeArray}
            getOptionLabel={(e) => e.label}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                from: e.value,
              }))
            }
            placeholder="Select start time"
            getOptionValue={(e) => e.label}
          />
        </Grid>
        <Grid item lg={6}>
          <Select
            maxMenuHeight={180}
            className="basic-single mb-2 "
            classNamePrefix="select"
            value={timeArray?.find((e) => e.value === to)}
            isSearchable={true}
            options={timeArray}
            getOptionLabel={(e) => e.label}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                to: e.value,
              }))
            }
            placeholder="Select end time"
            getOptionValue={(e) => e.label}
          />
        </Grid>

        <Grid item lg={6}>
          <label>Agent Days Availability</label>
          <span className="mr-3 w-30">
            <Select
              maxMenuHeight={180}
              className="basic-single mb-2 "
              classNamePrefix="select"
              value={weekOptions?.find((e) => e.value === days)}
              isSearchable={true}
              options={weekOptions}
              getOptionLabel={(e) => e.label}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  days: e.value,
                }))
              }
              placeholder="Select "
              getOptionValue={(e) => e.label}
            />
          </span>
        </Grid>
        <Grid item lg={6}>
          <label>Timezone</label>
          <span className="mr-3 w-30">
            <Select
              maxMenuHeight={100}
              className="basic-single mb-5 "
              classNamePrefix="select"
              value={timezones.find((e) => e.offset === timezone)}
              isSearchable={true}
              options={timezones}
              getOptionLabel={(e) => `${e.text} ${e.abbr} `}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  timezone: e.offset,
                }))
              }
              placeholder="Select "
              getOptionValue={(e) => e.offset}
            />
          </span>
        </Grid>

        <Grid item lg={6}>
          <Input
            placeholder={"Company Homepage Url"}
            value={homepageUrl}
            name={"homepageUrl"}
            onChange={onChange}
            className={"mr-3"}
            white={true}
          />{" "}
          <button className="normal-button mr-1" onClick={getCompanySummary}>
            Get Company Summary
          </button>
        </Grid>
      </Grid>

      <Grid>
        <MultiLineGrowInput
          placeholder={"Company Summary"}
          value={summary}
          name={"summary"}
          onChange={onChange}
          className={"mr-3 mt-3"}
          white={true}
        />{" "}
      </Grid>
      <br />

      <div className="d-flex ">
        {" "}
        <button className="normal-button mr-1" onClick={saveCompanyDeatils}>
          Save
        </button>
      </div>
    </div>
  );
}

export default CompanyDetails;
