import {
  ScheduleComponent,
  Day,
  Week,
  WorkWeek,
  Month,
  Agenda,
  Inject,
} from "@syncfusion/ej2-react-schedule";
import React, { useEffect, useState } from "react";
import "../../Assests/Css/Calender.css";
import { registerLicense } from "@syncfusion/ej2-base";
import { useDispatch } from "react-redux";
import {
  getCalederSlotsAction,
  rescheduleEventAction,
} from "../../Redux/Actions/Common.actions";
import { useParams } from "react-router-dom";
import moment from "moment";
registerLicense(
  "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXdfc3RXQmRZVkZ2Vks="
);

function Calender() {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [localEventData, setLocalEventData] = useState([]);
  useEffect(() => {
    const init = async () => {
      let res = await dispatch(getCalederSlotsAction(projectId));
      let jk = [];
      console.log(res?.data);
      res?.data?.forEach((ev) => {
        jk.push({
          Id: ev._id,
          Subject: ev.eventName,
          Location: ev.meetingUrl,
          StartTime: new Date(Number(ev.startTime)),
          EndTime: new Date(Number(ev.endTime)),
          Description: ev.description,
          dealId: ev.dealId,
          projectId: ev.projectId,
        });
      });

      setLocalEventData(jk);
    };
    init();
  }, []);

  const onActionComplete = async (args) => {
    if (args.requestType === "eventChanged") {
      const updatedEvent = args?.data[0];
      console.log("updatedEventupdatedEvent", updatedEvent);
      const formattedDate = moment(updatedEvent.StartTime).format("D-M-YYYY");
      const momentDate1 = moment(updatedEvent.StartTime);
      const momentDate2 = moment(updatedEvent.EndTime);
      let obj = {
        eventName: updatedEvent.Subject,
        eventDate: formattedDate,
        startTime: new Date(updatedEvent.StartTime).getTime(),
        endTime: new Date(updatedEvent.EndTime).getTime(),
        description: updatedEvent.Description,
        meetingUrl: updatedEvent.Location,
        dealId: updatedEvent.dealId,
        projectId: projectId,
        duration: momentDate2.diff(momentDate1, "minutes"),
      };
      await dispatch(rescheduleEventAction(obj));
      console.log(obj);
    }
  };
  return (
    <ScheduleComponent
      eventSettings={{
        dataSource: localEventData,
        allowAdding: false,
      }}
      actionComplete={onActionComplete}
    >
      <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
    </ScheduleComponent>
  );
}
export default Calender;
