import { Box } from "@mui/material";
import { lazy } from "react";
import GroupEditing from "../Calender/Calender";
import CallSettingsScreen from "../ProjectSection/Projects/CallSettingsScreen";
import CompanyDetails from "../AccountSection/Pages/CompanyDetails";
import LiveAgent from "../AccountSection/Pages/LiveAgent";
import TrainingLog from "../ProjectSection/TrainingData/TrainingLog";
import ScreenConfig from "../ProjectSection/ScreenConfig/ScreenConfig";
import Dashboard from "../Pages/Dashboard/Dashboard";

const Main = lazy(() => import("../Pages/Main"));
const DealsView = lazy(() => import("../ProjectSection/Deals/DealsView"));
const Projects = lazy(() => import("../ProjectSection/Projects/Projects"));
const DealsBoard = lazy(() => import("../ProjectSection/Deals/Deals"));

const NotFound = () => {
  return (
    <div className="black-bg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            width: 300,
            height: "auto",
          }}
        >
          <h1>404- Not found</h1>
        </Box>
      </Box>
    </div>
  );
};

const routes = {
  screenConfig: {
    path: "/screen-config",
    element: ScreenConfig,
  },
  trainginLogs: {
    path: "/training-logs",
    element: TrainingLog,
  },
  liveAgent: {
    path: "/live-agent-details",
    element: LiveAgent,
  },
  companyDetails: {
    path: "/company-details",
    element: CompanyDetails,
  },
  main: {
    path: "/main",
    element: Main,
  },
  calender: {
    path: "/calender",
    element: GroupEditing,
  },
  callSettings: {
    path: "/project-settings",
    element: CallSettingsScreen,
  },
  dealsView: {
    path: "/deals/view",
    element: DealsView,
  },
  deals: {
    path: "/deals",
    element: DealsBoard,
  },
  projects: {
    path: "/projects",
    element: Projects,
  },
  dashboard: {
    path: "/dashboard",
    element: Dashboard,
  },
  invalid: {
    path: "/*",
    element: NotFound,
  },
};

export { routes };
