import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { Input } from "../CommonComponents/Input";
import { useDispatch } from "react-redux";

function DemoForm() {
  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    linkedinUrl: "",
  });
  const { firstName, lastName, email, phone, linkedinUrl } = state;
  const dispatch = useDispatch();
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const saveDeal = () => {
    let obj = {
      ...state,
    };
    dispatch();
  };
  return (
    <div>
      {" "}
      <Typography className="mb-3">Phone Bot Section</Typography>
      <Grid container spacing={2}>
        {}

        <Grid item lg={6}>
          <Input
            label={"First Name"}
            value={firstName}
            name={"firstName"}
            onChange={onChange}
            className={"w-100"}
          />
        </Grid>
        <Grid item lg={6}>
          <Input
            label={"Last Name"}
            value={lastName}
            name={"lastName"}
            onChange={onChange}
            className={"w-100"}
          />
        </Grid>
        <Grid item lg={6}>
          <Input
            label={"Email"}
            value={email}
            name={"email"}
            onChange={onChange}
            className={"w-100"}
          />
        </Grid>
        <Grid item lg={6}>
          <Input
            label={"Phone"}
            value={phone}
            name={"phone"}
            onChange={onChange}
            className={"w-100"}
          />
        </Grid>
        <Grid item lg={6}>
          <Input
            label={"LinkedIn Url"}
            value={linkedinUrl}
            name={"linkedinUrl"}
            onChange={onChange}
            className={"w-100"}
          />
        </Grid>
      </Grid>
      <div className="d-flex justify-content-center">
        <button className="get-started-button mt-4" onClick={() => saveDeal()}>
          Save & Next
        </button>
      </div>
    </div>
  );
}

export default DemoForm;
