import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getDateWithTime } from "../../CommonComponents/Common";
import { getTrainingLogsAction } from "../../../Redux/Actions/AI.actions";

const BuildLog = () => {
  const { projectId } = useParams();
  const reducer = useSelector((state) => state);

  const {
    AccountReducer: { systemConstant, systemConstantAccount },
  } = reducer;
  const [trainingData, setTrainingData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const TRAINING_DATA = systemConstantAccount?.find(
      (o) => o.type === "AGENT_TRAINING" && o.projectId === projectId
    );
    setTrainingData(TRAINING_DATA?.details || []);
  }, [systemConstantAccount]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState({});
  const [fetchedData, setFetchedData] = useState({});
  const dispatch = useDispatch();
  const handleAccordionChange =
    (index, sessionId) => async (event, isExpanded) => {
      setExpanded(isExpanded ? index : false);
      if (isExpanded && !fetchedData[index]) {
        setLoading((prev) => ({ ...prev, [index]: true }));
        try {
          let local = "e0c794af-2aa7-419e-8605-97c41ecbbb33";
          let res = await dispatch(getTrainingLogsAction(sessionId, "private"));
          setFetchedData((prev) => ({ ...prev, [index]: res }));
          setLoading((prev) => ({ ...prev, [index]: false }));
        } catch (error) {
          console.error("API call failed:", error);
          setLoading((prev) => ({ ...prev, [index]: false }));
        }
      }
    };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 2,
          mb: 2,
        }}
      >
        <Typography
          className="font-size-16"
          style={{ color: "var(--text-color)" }}
        >
          Training Logs
        </Typography>
      </Box>

      {trainingData.map((step, index) => (
        <Accordion
          key={index}
          expanded={expanded === index}
          onChange={handleAccordionChange(index, step?.trainingSessionID)}
          sx={{
            borderRadius: "5px",
            margin: "3px",
            marginBottom: "10px",
            backgroundColor: "var(--background-color)",
            color: "var(--text-color)",
            border: "1px solid var(--border-color)",
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{ color: "var(--default-icon-color)" }}
                onClick={() => console.log("clicked")}
              />
            }
          >
            <CheckCircleOutlineIcon
              sx={{ color: "var(--default-icon-color)", marginRight: 1 }}
            />
            <Typography>Trainging Status {step?.status}</Typography>
            <Typography
              className="font-size-14"
              sx={{ color: "#a0a0a0", marginLeft: "auto" }}
            >
              {getDateWithTime(step?.timestamp)} {step.duration}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {loading[index] ? (
              <CircularProgress />
            ) : (
              <List>
                {fetchedData[index] ? (
                  fetchedData[index]?.logs?.map((data) => (
                    <>
                      <ListItem>
                        <ListItemText primary={data?.message} />
                      </ListItem>
                      <Divider />
                    </>
                  ))
                ) : (
                  <ListItem>
                    <ListItemText primary="No data available." />
                  </ListItem>
                )}
              </List>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default BuildLog;
