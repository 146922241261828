import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Box, Typography, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: 3,
  p: 0,
  minWidth: "80vw",
};

const CommanModal = ({
  open,
  headerText,
  subheaderText,
  toggle,
  body,
  minWidth,
  footer = true,
}) => {
  style.minWidth = minWidth ? minWidth : style.minWidth;
  const inputRef = useRef(null);
  const [againopen, setAgainOpen] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        const length = inputRef.current.value.length;
        inputRef.current.setSelectionRange(length, length);
      }
    });

    return () => clearTimeout(timeout);
  }, [againopen, inputRef]);
  return (
    <div>
      <Modal
        open={open}
        onClose={toggle}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style} className="modal-bg">
          <div className="custom-header">
            <Typography
              id="modal-title"
              className="font-size-17"
              sx={{ fontWeight: "bold" }}
            >
              {headerText} <span style={{ color: "red" }}>*</span>
            </Typography>
            <Typography
              className="font-size-14"
              sx={{ mt: 1, color: "#6c757d" }}
            >
              {subheaderText}
            </Typography>
          </div>

          {body}

          {footer && (
            <Box sx={{ display: "flex", justifyContent: "end", mt: 3, mb: 3 }}>
              <button onClick={toggle} className="skip-button mr-4">
                Close
              </button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default CommanModal;
