import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { Box, Grid } from "@mui/material";
import { updateProjectsAction } from "../../../Redux/Actions/Project.action";
import CustomScrollbar from "../../CommonComponents/CustomScrollbar";

const CallSettings = ({ toggle }) => {
  const reducer = useSelector((state) => state);
  const { projectId } = useParams();

  const {
    AccountReducer: { systemConstant },
    ProjectReducer: {
      selectedProject: { callSettings },
    },
  } = reducer;

  console.log("cal;ll", callSettings);
  const [state, setState] = useState({
    callerIdNumber: "",
    callTransferNumber: "",
    asrEngine: "",
    gateway: "",
    voice: "",
    languageCode: "",
    model: "001",
  });

  const [constantArray, setConstantArray] = useState({
    asrEngines: [],
    gateways: [],
    voices: [],
    modelOptions: [],
    languageCodes: [],
  });

  const { asrEngines, gateways, voices, modelOptions, languageCodes } =
    constantArray;

  useEffect(() => {
    let voicesCopy = systemConstant?.voices;
    voicesCopy = voicesCopy?.map((voic) => {
      return {
        ...voic,
        isDisabled: voic.disabled,
      };
    });
    setConstantArray({ ...systemConstant, voices: voicesCopy });
  }, [systemConstant]);

  useEffect(() => {
    if (!isEmpty(callSettings)) {
      let localCallSetting = callSettings;
      setState((prev) => ({
        ...prev,
        ...localCallSetting,
      }));
    }
  }, [callSettings]);

  const {
    asrEngine,
    gateway,
    voice,
    languageCode,
    model,
    callTransferNumber,
    callerIdNumber,
  } = state;

  const dispatch = useDispatch();

  const onChange = (e) => {
    e.persist();
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
  };

  const variantArray = [
    "primary",
    "secondary",
    "success",
    "danger",
    "warning",
    "info",
    "light",
    "dark",
  ];

  const resultArray = voices?.map((item, index) => ({
    item: item,
    variant: variantArray[index % variantArray.length],
  }));

  const saveSettings = async () => {
    let obj = {
      callSettings: {
        model,
        asrEngine,
        languageCode,
        voice,
        callerIdNumber,
        callTransferNumber,
        gateway,
      },
    };

    await dispatch(updateProjectsAction(obj, projectId));
  };
  return (
    <>
      <CustomScrollbar height="45vh">
        <Grid container spacing={2} p={1}>
          <Grid item sm={12}>
            <label>
              Gateway <span className="required-star">*</span>
            </label>
            <Select
              maxMenuHeight={160}
              className="basic-single"
              classNamePrefix="select"
              value={gateways?.find((name) => name.value === gateway)}
              isDisabled={false}
              isSearchable={true}
              options={gateways}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  gateway: e.value,
                }));
              }}
              placeholder="Select Gateway"
            />
          </Grid>
          <Grid item sm={12}>
            <label>
              Model <span className="required-star">*</span>
            </label>
            <Select
              maxMenuHeight={160}
              className="basic-single"
              classNamePrefix="select"
              value={modelOptions?.find((name) => name.value === model)}
              isDisabled={false}
              isSearchable={true}
              options={modelOptions}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  model: e.value,
                }));
              }}
              placeholder="Select Model"
            />
          </Grid>
          <Grid item sm={12}>
            <label>
              Voice <span className="required-star">*</span>
            </label>

            <Select
              maxMenuHeight={160}
              className="basic-single"
              classNamePrefix="select"
              value={voices?.find((name) => name.value === voice)}
              isDisabled={false}
              isSearchable={true}
              options={voices}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  voice: e.value,
                }));
              }}
              placeholder="Select Voice"
            />
          </Grid>
          <Grid item sm={12}>
            <label>
              Language Code <span className="required-star">*</span>
            </label>

            <Select
              maxMenuHeight={180}
              className="basic-single"
              classNamePrefix="select"
              value={asrEngines?.find((name) => name.value === asrEngine)}
              isDisabled={false}
              isSearchable={true}
              options={asrEngines}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  asrEngine: e.value,
                }));
              }}
              placeholder="Select Engine"
            />
          </Grid>
          <Grid item sm={12}>
            <label>
              Language Code <span className="required-star">*</span>
            </label>

            <Select
              maxMenuHeight={180}
              className="basic-single"
              classNamePrefix="select"
              value={languageCodes?.find((name) => name.value === languageCode)}
              isDisabled={false}
              isSearchable={true}
              options={languageCodes}
              getOptionLabel={(e) => e.name}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  languageCode: e.value,
                }));
              }}
              placeholder="Select Language Codes"
            />
          </Grid>
        </Grid>
      </CustomScrollbar>
      <div className="d-flex justify-content-center m-3 mt-5">
        <button className="gradient-button" onClick={saveSettings}>
          Save Settings
        </button>
        <button className="gradient-button ml-1" onClick={toggle}>
          Close
        </button>
      </div>
    </>
  );
};

export default CallSettings;
