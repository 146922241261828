import React from "react";
import "../../Assests/Css/Onboarding.css";
import img from "../../Assests/Images/Backgrounds/MiddleCircle.svg";
import { useNavigate } from "react-router-dom";
function FirstPage() {
  const navigate = useNavigate();
  return (
    <>
      <div className="App">
        <div className="container">
          <div className="welcome">Welcome To</div>
          <div className="maverick">Maverick</div>

          <div className="buttons">
            <button className="request-access">Request Access</button>
            <button
              className="how-it-works"
              onClick={() => navigate("/get-started/create-project")}
            >
              How it works
            </button>
          </div>
        </div>
      </div>
      <div className="image-container">
        <img src={img} alt="Your" />
      </div>
    </>
  );
}

export default FirstPage;
